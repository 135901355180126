<template>
  <div>
    <tabs
      :show.sync="active" :theme="theme" keep-alive
      :hold="shouldHold"
    >
      <tab
        v-for="d in dashboards" :key="d.id" :title="d.title"
        :hash="genHash(d.id)"
      >
        <widget-wall
          ref="widgetwall" :layout.sync="activeDashboard.layout" :widgets="widgets"
          :customizing="customizing"
          @remove:widget="removeWidget"
          @change:widgets="changeWidgets"
        />
      </tab>
      <tab
        title="" :icon="plus" ghost
        @click.prevent="create"
      />
      <tab
        ghost end
      >
        <template v-slot:nav="data">
          <div :class="data.class">
            <dropdown>
              <a slot="v" href="#" style="color: inherit;">
                <octicon :icon="clock" /> {{ currentRefreshOption ? currentRefreshOption.name : actualRefresh + 's' }}
              </a>

              <a
                v-for="refreshOption in refreshOptions" :key="refreshOption.value" class="dropdown-item"
                href="#" @click.prevent="updateRefresh(refreshOption.value)"
              >
                {{ $t('refresh') }}: {{ refreshOption.name }}
              </a>
            </dropdown>
          </div>
        </template>
      </tab>
      <tab
        :title="customizing ? $t('save') : $t('customize')" :icon="paintcan"
        ghost @click.prevent="customize"
      />
      <tab
        v-if="customizing" :title="$t('cancel')" :icon="sync"
        ghost @click.prevent="customizeCancel"
      />
      <tab
        :title="$t('edit')" :icon="pencil" ghost
        @click.prevent="edit"
      />
      <tab
        :title="$t('remove')" :icon="x" ghost
        @click.prevent="remove"
      />
    </tabs>
    <modal v-if="item" :show.sync="modal">
      <box>
        <div slot="header">
          {{ item.title || $t('newDashboard') }}
        </div>

        <form>
          <div class="field">
            <label class="label">{{ $t('title') }}</label>
            <div class="control">
              <input v-model="item.title" class="input" type="text">
            </div>
          </div>

          <div class="field">
            <label class="label">{{ $t('description') }}</label>
            <div class="control">
              <input v-model="item.description" class="input" type="text">
            </div>
          </div>

          <div slot="footer" class="field is-grouped is-grouped-right">
            <div class="control">
              <button type="button" class="button is-link" @click.prevent="submit">
                {{ $t('save') }}
              </button>
            </div>
            <div class="control">
              <button type="button" class="button is-link is-light" @click.prevent="closeModal">
                {{ $t('cancel') }}
              </button>
            </div>
          </div>
        </form>
      </box>
    </modal>
  </div>
</template>
<script>
import { pencil, plus, x, paintcan, check, sync, clock } from 'octicons-vue'
import { Tabs, Tab, Modal } from '@cyradar/ui'
import WidgetWall from '@/components/WidgetWall'

export default {
  name: 'Home',
  components: {
    Tabs,
    Tab,
    Modal,
    WidgetWall
  },
  data () {
    return {
      active: 0,
      dashboards: [],
      customizing: false,
      widgets: [],
      widgetsChanged: {},
      item: null,
      modal: false,
      interval: null
    }
  },
  computed: {
    isDark () {
      return this.$store.state.ui.dark
    },
    refreshOptions () {
      return [
        {
          name: '1m',
          value: 60
        },
        {
          name: '5m',
          value: 300
        },
        {
          name: '10m',
          value: 600
        },
        {
          name: '15m',
          value: 900
        }
      ]
    },
    currentRefreshOption () {
      return this.refreshOptions.find(o => o.value === this.actualRefresh)
    },
    theme () {
      const t = Object.assign({}, this.$_ui_theme_tabs)
      t.item = `${t.item} tabs-module_item`
      t.octicon = `${t.octicon} tabs-module_octicon`
      return t
    },
    pencil () {
      return pencil
    },
    plus () {
      return plus
    },
    x () {
      return x
    },
    clock () {
      return clock
    },
    paintcan () {
      if (this.customizing) {
        return check
      }

      return paintcan
    },
    refresh () {
      return (this.activeDashboard && this.activeDashboard.refresh) ? this.activeDashboard.refresh : 10
    },
    actualRefresh () {
      if (this.refresh === 0) {
        return 0
      }

      return this.refresh <= this.refreshOptions[0].value ? this.refreshOptions[0].value : this.refresh
    },
    sync () {
      return sync
    },
    activeDashboard () {
      if (!this.dashboards || !this.dashboards.length) {
        return
      }

      return this.dashboards[this.active]
    },
    widgetIDs () {
      if (!this.dashboards || !this.dashboards.length) {
        return []
      }

      const arr = this.dashboards.map(x => x.layout.map(y => y.i)).reduce((f, toBeF) => {
        return f.concat(toBeF)
      }, [])

      return [...new Set(arr)]
    }
  },
  watch: {
    actualRefresh: {
      immediate: true,
      handler (val) {
        if (this.interval) {
          window.clearInterval(this.interval)
        }

        if (val === 0) {
          return
        }

        this.interval = window.setInterval(() => {
          if (!(this.$refs.widgetwall instanceof Array)) {
            return
          }

          this.$refs.widgetwall.forEach(wall => {
            if (typeof wall.refresh !== 'function') {
              return
            }

            wall.refresh()
          })
        }, val * 1000)
      }
    }
  },
  beforeDestroy () {
    if (this.interval) {
      window.clearInterval(this.interval)
      this.interval = null
    }
  },
  mounted () {
    this.load().then(this.loadWidgets)
  },
  methods: {
    shouldHold (item, index) {
      if (!this.customizing) {
        return
      }

      this.$store.dispatch('NOTIFY', {
        type: 'warning',
        error: {
          message: this.$t('home.saveFirst')
        }
      })
      return true
    },
    changeWidgets (change) {
      this.widgetsChanged = change
    },
    closeModal () {
      this.modal = false
    },
    submit () {
      if (this.item.id) {
        this.update()
        return
      }

      this.store()
    },
    create () {
      this.item = {}
      this.modal = true
    },
    edit () {
      if (!this.activeDashboard) {
        return
      }
      this.item = this.$_stateClone(this.activeDashboard)
      this.modal = true
    },
    customize () {
      if (this.customizing) {
        this.customizing = false
        this.updateWidgets()
        this.update()
        return
      }

      if (this.interval) {
        window.clearInterval(this.interval)
      }

      this.$_statePersist({
        item: this.activeDashboard || {},
        widgets: this.widgets
      })

      this.item = this.activeDashboard
      this.customizing = true
    },
    customizeCancel () {
      if (!this.customizing) {
        return
      }

      const [item, widgets] = this.$_stateRestore(['item', 'widgets'])
      this.$set(this.dashboards, this.active, item)
      this.widgets = widgets
      this.customizing = false
    },
    load () {
      return this.$http.get('/api/v1/dashboards').then(body => {
        if (!body || !body.data || !body.data.data) {
          return
        }
        this.dashboards = body.data.data
      })
    },
    store () {
      return this.$http.post('/api/v1/dashboards', this.item).then(body => {
        if (!body || !body.data) {
          return
        }

        this.closeModal()
        this.$store.dispatch('NOTIFY', {
          type: 'success',
          text: body.data.message
        })

        this.load()
      })
    },
    update () {
      return this.$http.patch(`/api/v1/dashboards/${this.item.id}`, this.item).then(body => {
        if (!body || !body.data) {
          return
        }

        this.closeModal()
        this.$store.dispatch('NOTIFY', {
          type: 'success',
          text: body.data.message
        })

        this.load()
      })
    },
    updateRefresh (val) {
      this.item = this.activeDashboard
      this.item.refresh = parseInt(val)
      this.update()
    },
    remove () {
      if (!this.activeDashboard) {
        return
      }

      if (!window.confirm(this.$t('rus'))) {
        return
      }

      return this.$http.delete(`/api/v1/dashboards/${this.activeDashboard.id}`).then(body => {
        if (!body || !body.data) {
          return
        }

        this.$store.dispatch('NOTIFY', {
          type: 'success',
          text: body.data.message
        })

        this.dashboards.splice(this.active, 1)
        this.active = 0
      })
    },
    loadWidgets () {
      return this.$http.post('/api/v1/widgets/search', { ids: this.widgetIDs }).then(body => {
        if (!body || !body.data || !body.data.data) {
          return
        }

        this.widgets = body.data.data
      })
    },
    updateWidgets () {
      return Promise.all(
        Object.keys(this.widgetsChanged).map(id => {
          return this.$http.patch(`/api/v1/widgets/${id}`, this.widgetsChanged[id])
        })
      )
    },
    removeWidget (id) {
      if (!this.activeDashboard) {
        return
      }

      if (!window.confirm(this.$t('rus'))) {
        return
      }

      this.activeDashboard.layout = this.activeDashboard.layout.filter(w => w.i !== id)
    },
    genHash (id) {
      return id.substring(0, 3) + id.substring(id.length - 3)
    }
  }
}
</script>
<style lang="scss">
.tabs-module_item {
  display: flex;
}

.tabs-module_octicon {
  align-self: center;
  margin-right: .25em;
}
</style>
